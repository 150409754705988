// 过滤器
import Vue from 'vue'
Vue.filter('transformTab', function (value) {
    if (!value) return ''
    const isEn = value.match(/[a-zA-Z]/)
    // 双向的转换， 汉字 <--> 英文
    if (isEn) {
        switch (value) {
            case '': return '首页'
            case 'introduce': return '学会介绍'
            case 'notice': return '学会通知'
            case 'action': return '学会动态'
            case 'progress': return '科技进展'
            case 'service': return '会员服务'
            case 'contact': return '联系我们'
            case 'info': return '消息通知'
        }
    } else {
        switch (value) {
            case '首页': return '/'
            case '学会介绍': return '/introduce'
            case '学会通知': return '/notice'
            case '学会动态': return '/action'
            case '科技进展': return '/progress'
            case '会员服务': return '/service'
            case '联系我们': return '/contact'
            case '消息通知': return '/info'
        }
    }
})

