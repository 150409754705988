import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Mint from "mint-ui";
// import "mint-ui/lib/style.css";
// Vue.use(Mint);
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import { Toast } from "vant";
Vue.prototype.$Toast = Toast;
Vue.config.productionTip = false;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// import { Select, Option } from 'view-design'
// import 'view-design/dist/styles/iview.css'

// Vue.component('Select', Select)
// Vue.component('Option', Option)

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
const hls = require('videojs-contrib-hls')
Vue.use(hls)
Vue.use(VideoPlayer);

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import '@/http/axios'
import topHeader from "@/comm/header"
import foot from "@/comm/foot"
Vue.component('topHeader',topHeader)
Vue.component('foot',foot)
import './filter/index' 

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");