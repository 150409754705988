import axios from "axios"; // 该处引入axios模块

// 测试连接


axios.defaults.baseURL = process.env.VUE_APP_URL + '/api/'

axios.defaults.timeout = 10000
axios.interceptors.request.use(
    config => {
        // if (config.method == "post") {
        //     if (!config.data) {
        //         config.data = {};
        //     }
        // } else if (config.method == "get") {
        //     if (!config.params) {
        //         config.params = {};
        //     }
        // }
        // const token = JSON.parse(window.sessionStorage.getItem('token')) 
        // if (token) {
        //     config.headers.Authorization = token
        //     return config
        // }else{
        //     return config
        // }
		if(localStorage.getItem("uuid")){
			config.headers.Authorization = localStorage.getItem("uuid");
		}else{
            // if(!isGet) {
            //     // Toast('请先进行授权');
            //     //没有授权过
            //     // getToken();
            // }
		}
		
        // console.log(config); // 该处可以将config打印出来看一下，该部分将发送给后端（server端）
        return config; // 对config处理完后返回，下一步将向后端发送请求
    },
    error => {
        // 当发生错误时，执行该部分代码
        console.log(error); //调试用
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axios;