import Vue from "vue";
import VueRouter from "vue-router";
import allApi from "@/http/allApi.js"
import store from "../store";
Vue.use(VueRouter);

const routes = [{
		path: "/",
		name: "homePage",
		meta: {
			title: '首页'
		},
		component: resolve => require(["@/views/homePage.vue"], resolve)
	},
	{
		path:'/people',
		name:'people',
		meta: {
			title: '学会负责人'
		},
		component: resolve => require(["@/views/people.vue"], resolve)
	},
	{
		path:'/people/:id',
		name:'peoplepage',
		component: resolve => require(["@/views/peoplePage.vue"], resolve)
	},
	{
		path: '/introduce',
		name: 'introduce',
		meta: {
			title: '学会介绍'
		},
		component: resolve => require(["@/views/introduce.vue"], resolve)
	},
	{
		path: '/notice',
		name: 'notice',
		meta: {
			title: '学会通知'
		},
		component: resolve => require(["@/views/notice.vue"], resolve)
	},
	{
		path: '/notice/:id',
		name: 'noticepage',
		component: resolve => require(["@/views/noticePage.vue"], resolve)
	},
	{
		path: '/action',
		name: 'action',
		meta: {
			title: '学会动态'
		},
		component: resolve => require(["@/views/action.vue"], resolve),
	},
	{
		path:'/action/:id',
		name:'actionpage',
		component: resolve => require(["@/views/actionPage.vue"], resolve),
	},
	{
		path: '/progress',
		name: 'progress',
		meta: {
			title: '科技进展'
		},
		component: resolve => require(["@/views/progress.vue"], resolve)
	},
	{
		path: '/progress/:id',
		name: 'progresspage',
		component: resolve => require(["@/views/progressPage.vue"], resolve)
	},
	{
		path: '/service',
		name: 'service',
		meta: {
			title: '会员服务'
		},
		component: resolve => require(["@/views/service.vue"], resolve)
	},
	{
		path: '/contact',
		name: 'contact',
		meta: {
			title: '联系我们'
		},
		component: resolve => require(["@/views/contact.vue"], resolve)
	},
	{
		path: '/info',
		name: 'info',
		meta: {
			title: '消息通知'
		},
		component: resolve => require(["@/views/info.vue"], resolve)
	},
	{
		path: '/info/:id',
		name: 'infopage',
		meta: {
			title: '消息通知'
		},
		component: resolve => require(["@/views/infoPage.vue"], resolve)
	},
	{
		path: '/search/:searchText',
		name: 'search',
		meta: {
			title: '搜索结果'
		},
		component: resolve => require(["@/views/search.vue"], resolve)
	},
	{
		path: '/forget',
		name: 'forget',
		meta: {
			title: '忘记密码'
		},
		component: resolve => require(["@/views/forget.vue"], resolve)
	},
];

// 获取原型对象上的push函数
// const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const router = new VueRouter({
	routes,
	scrollBehavior:({to,from,savePosition})=>{
		if(savePosition){
			return savePosition
		}
	},
});

router.beforeEach((to, from, next) => {
	// if(to.path == '/myCenter/myCase'){
    //     store.commit('changeMyCenter',1)
    // }else if(to.path == '/myCenter/myMsg'){
    //     store.commit('changeMyCenter',2)
    // }else if(to.path == '/myCenter/myLike'){
    //     store.commit('changeMyCenter',3)
    // }else if(to.path == '/myCenter/mySay'){
    //     store.commit('changeMyCenter',4)
    // }
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	
	// if(to.path == '/'){
		// //首页
		// localStorage.setItem("is_home", 1);
	// }else{
		//非首页
		// localStorage.setItem("is_home", 0);
	// }
	next()
});
//路由跳转到新页面 到新页面顶部
// router.afterEach(()=>{
// 	window.scrollTo(0,0)
// })

// router.afterEach((to, from, next) => {
// 	let path = window.location.href;
// 	if(path){
// 		if(path.length < 200){
// 			//记录页面访问路径
// 			allApi.getpage_visit({'page':path,'name':to.meta.title}).then(function(res){        });
// 		}
// 	}
// });

//设置名称为name,值为value的Cookie
// function setCookie(name, value) {
// 	var expdate = new Date();
// 	expdate.setTime(expdate.getTime() + 30 * 60 * 1000);
// 	document.cookie = name + "=" + value + ";expires=" + expdate.toGMTString() + ";path=/";
// }

//获取cookie
// function getCookie(cname) {
// 	var name = cname + "=";
// 	var ca = document.cookie.split(';');
// 	for (var i = 0; i < ca.length; i++) {
// 		var c = ca[i];
// 		while (c.charAt(0) == ' ') c = c.substring(1);
// 		if (c.indexOf(name) != -1) {
// 			return c.substring(name.length, c.length);
// 		}
// 	}
// 	return "";
// }
export default router;
