<template>
    <div class="header">
        <div class="top">
            <div class="top_text">
                <!-- <div class="logo">
                    <img src="../assets/image/homePage/logo.png" alt="">
                </div> -->
                <div class="text">
                    <h1 style="letter-spacing: 8px;">河北省增材制造学会</h1>
                    <p>Hebei Additive Manufacturing Association</p>
                </div>
            </div>
        </div>
        <div class="nav">
            <div>
                <router-link :class="index == ind ? 'active' : ''" :to="tab | transformTab" v-for="(tab,index) in tabs" :key='tab'>
                    {{tab}}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['ind'],
        data(){
            return{
                tabs:['首页','学会介绍','学会通知','学会动态','科技进展','会员服务','联系我们']
            }
        },
        mounted(){
            if(this.isLogin){
                this.tabs.push('消息通知')
            }
        },
        computed:{
            isLogin(){
                return localStorage.getItem('uuid') ? true : false
            }
        }
    }
</script>

<style scoped lang='less'>

.header{
    width: 100%;
    min-width: 1200px;
    height: 400px;
    .top{
        background-image: url('../assets/image/homePage/topbgc.png');
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        .top_text{
            width: 1200px;
            min-width: 1200px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            .logo{
                margin-right: 21px;
            }
            .text{
                color: white;
                h1{
                    font-size: 60px;
                    line-height: 0;
                }
                p{
                    font-size: 26px;
                    margin: 0;
                    font-weight: 400;
                    letter-spacing: 2px;
                }
            }
        }
    }
    .nav{
        height: 50px;
        background-color: #1D7FD7;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
            width: 1200px;
            min-width: 1200px;
            display: flex;
            flex-direction: row;
            /* margin-left: 31px; */
            a:nth-child(1){
               padding: 0 31px;
            }
            a{
                font-size: 18px;
                height:46px;
                line-height:46px;
                margin-top:4px;
                color: #ffffff;
                padding: 0 13px;
                font-weight: 400;
                margin-right: 2px;
            }
            a:hover,.active{
                background-color: #ffffff;
                opacity: 0.9;
                color: #1D7FD7;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }
    }
}

</style>