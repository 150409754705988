<style scoped lang="less">
    .foot{
        width: 100%;
        min-width: 1200px;
        height: 163px;
        min-height: 163px;
        background: #1D7FD7;
        display: flex;
        justify-content: center;
        .footer{
            width: 1200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .nav{
                height: 50px;
                background-color: #1D7FD7;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 32px;
                margin-left: 31px;
                div{
                    width: 784px;
                    display: flex;
                    flex-direction: row;
                    a:nth-child(1){
                        // padding: 0 31px;
                    }
                    a{
                        font-size: 18px;
                        color: #ffffff;
                        padding: 0 13px;
                        font-weight: 400;
                    }
                }
            }
            .text{
                width: 225px;
                font-size: 12px;
                color: #ffffff;
            }
        }
    }
</style>
<template>
    <div class="foot">
        <div class="footer">
            <div class="nav">
                <div>
                    <router-link class="" :to="tab | transformTab" v-for="tab in tabs" :key='tab'>
                        {{tab}}
                    </router-link>
                </div>
            </div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" class="text">
                冀ICP备19024670号-5
                <!-- © 2022 Wendy  版权所有   备12637837号 -->
            </a>
        </div>
    </div>  
</template>
<script>
export default {
        data(){
            return{
                tabs:['首页','学会介绍','学会通知','学会动态','科技进展','会员服务','联系我们']
            }
        },
        mounted(){
            if(this.isLogin){
                this.tabs.push('消息通知')
            }
        },
        computed:{
            isLogin(){
                return localStorage.getItem('uuid') ? true : false
            }
        }
}
</script>