<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isRouterAlive:true
			}
		},
		provide(){
			return{
				reload:this.reload
			}
		},
		methods: {
			reload(){
				this.isRouterAlive = false
				this.$nextTick(()=>{
					this.isRouterAlive = true
				})
			}
		}
	}
</script>


<style lang="less">
	*{
		box-sizing: border-box;
	}
	#app {
		width: 100%;
		// height: 100%;
		// 设置全局字体
		font-family: '' !important;
	}

	html,
	body {
		font-family: '' !important;
		margin: 0px;
		padding: 0px;
		height: 100%;
		width: 100%;
	}
</style>
